import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Container, FlexSpaceWrapper, PageTitle, FlexSpaceWrapperCenter, Gutter, WhiteBtn, MenuTitle, SearchLayout, TextInput, BlackBtnInput, WhiteBtnLink} from '../stylesheets/components';
import Routes from '../constants/routes';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { CityMapContainer, Pagination } from '../components';
import { numberWithDelimiter, tablize } from '../utils';
import _ from 'lodash';
import { Form, Field } from 'react-final-form';

const Fragment = React.Fragment;
const Hero = styled.div`
  background-color: white;
`;

const FlexSpaceBaseline = styled(FlexSpaceWrapper)`
  align-items: flex-end;
`;

const LabLogo = styled.a`
  position: absolute;
  right: 0;
  top: 40px;
`;

const HeroContainer = styled(Container)`
  padding: 40px 0 50px 0;
  position: relative;
`;  

const Column = styled.div`
  p {
    font-family: "Playfair Display";
    font-size: 1.2em;
    line-height: 1.4;
    color: white;
  }

  width: calc(50% - 20px);
`;

const Title = styled.h2`

  font-family: "Playfair Display";
  font-size: 3.571em;
  line-height: 1.0;
  font-weight: 600;
  color: black;
`;

const WikipediaDesc = styled.div`
  font-family: "Playfair Display";
  font-size: 1.2em;
  line-height: 1.4;
  padding: 10px 0 0 0;
`; 

const Disclaimer = styled.div`
  font-family: "Brandon Text";
  font-size: 0.9em;
  font-style: italic;
  color: #888;
  line-height: 1.2;
`;

const MapArea = styled.div`
  width: 100%;
  height: 60vh;
  position: relative;
`;

const TitleArea = styled(Container)`
  position: absolute;
  left: 50%;
  top: 15px;
  transform: translate(-50%, 0);
  margin: 0;
  z-index: 6;
`;

class UnverifiedPlaces extends Component {
  constructor(props){
    super(props);
    this.state = {
      county_subdivision: null,
      osm_roads: [],
      osm_roads_page: 1,
      place_names_page: 1,
      place_names: [],
      osm_roads_total_count: 0,
      place_names_total_count: 0,
      pg_search_documents: []
    }
  }
  componentDidMount(){
    this.loadData();
  }

  async loadData(){

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    const response = await fetch(`/api/county_subdivisions/${this.props.match.params.id}/unverified_places.json${this.props.location.search}`, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        county_subdivision: data.county_subdivision,
        osm_roads: data.osm_roads,
        osm_roads_page: data.osm_roads_page,
        place_names: data.place_names,
        place_names_page: data.place_names_page,
        osm_roads_total_count: data.osm_roads_total_count,
        place_names_total_count: data.place_names_total_count
      })
      // this.props.dispatch(changeMatchedPeople(data.fullname_search_people, data.lastname_search_people));

    } else {
      // let data = await response.json();
      console.log(response);
    }
  }


  async handleSubmit(values){

    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    // this.props.dispatch(changeProgress(true));
    const response = await fetch(Routes.api_search_names_within_city_path(), { method: "post", body: JSON.stringify(values), headers });
    if (response.ok) {
      let data = await response.json();
      console.log(data);
      this.setState({
        pg_search_documents: data.pg_search_documents
      })
    } else {
      let data = await response.json();

      // this.props.dispatch(changeProgress(false));
      return data;

    }

  }


  render() {
    let { windowWidth } = this.props;
    let { county_subdivision, osm_roads, place_names, osm_roads_page, place_names_page, osm_roads_total_count, place_names_total_count, pg_search_documents } = this.state;

    return (
      <Fragment>
        {
          county_subdivision ?  
          <Fragment>
            <Container>
              <Hero>
                <HeroContainer>
                  <FlexSpaceBaseline>
                    <Column>
                      <PageTitle>
                        { county_subdivision.properties.name }, { county_subdivision.properties.state_abbr }
                      </PageTitle>
                      <Gutter h={25} />
                      <WhiteBtnLink to={Routes.county_subdivision_path(county_subdivision.properties.gid)}>
                        Back
                      </WhiteBtnLink>
                    </Column>
                    <Column>
                    </Column>
                  </FlexSpaceBaseline>
                </HeroContainer>
              </Hero>

              <Gutter h={50} />
              <FlexSpaceWrapper>


                <Column>

                  
                  <FlexSpaceWrapperCenter>
                    <MenuTitle>
                      Unverified Street Names
                    </MenuTitle>

                    <Pagination 
                        page={osm_roads_page} 
                        per_page={30} 
                        total_count={osm_roads_total_count} 
                        page_params="osm_roads_page" 
                        url={Routes.unverified_places_county_subdivision_path} 
                        id={county_subdivision.properties.gid}
                        restParams={{
                          place_names_page: place_names_page
                        }}
                        />
                  </FlexSpaceWrapperCenter>
                  
                  <Gutter h={25} />

                  <table>
                    <thead>
                      <tr>
                        <th className="left">
                          Name
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        _.map(osm_roads, osm_road => {
                          return (
                            <tr key={osm_road.gid}>  
                              <td>
                                <Link to={Routes.osm_road_path(osm_road.gid)}>
                                  { osm_road.name }
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>

                </Column>


                <Column>
                  
                <FlexSpaceWrapperCenter>
                    <MenuTitle>
                      Unverified Place Names
                    </MenuTitle>

                    <Pagination 
                        page={place_names_page} 
                        per_page={30} 
                        total_count={place_names_total_count} 
                        page_params="place_names_page" 
                        url={Routes.unverified_places_county_subdivision_path} 
                        id={county_subdivision.properties.gid}
                        restParams={{
                          osm_roads_page: osm_roads_page
                        }}
                        />
                  </FlexSpaceWrapperCenter>
                  
                  <Gutter h={25} />

                  <table>
                    <thead>
                      <tr>
                        <th className="left">
                          Name
                        </th>
                        <th>
                          Type
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        _.map(place_names, place_name => {
                          return (
                            <tr key={place_name.id}>  
                              <td>
                                <Link to={`/${tablize(place_name.type)}/${place_name.id}`}>
                                  { place_name.name }
                                </Link>
                              </td>
                              <td>
                                {place_name.type}
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </Column>
              
              

              </FlexSpaceWrapper>
              <Gutter h={50} />
            </Container> 
          </Fragment> : null
        }
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth
  }
}

export default withRouter(connect(mapStateToProps)(UnverifiedPlaces));