import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Container, FlexSpaceWrapper, FlexSpaceWrapperCenter, Gutter, WhiteBtn, MenuTitle, SearchLayout, TextInput, BlackBtnInput, PageTitle } from '../stylesheets/components';
import Routes from '../constants/routes';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { SearchPersonMapContainer } from '../components';
import { numberWithDelimiter, tablize } from '../utils';
import _ from 'lodash';

const Fragment = React.Fragment;
const Hero = styled.div`
  background-color: white;
`;

const FlexSpaceBaseline = styled(FlexSpaceWrapper)`
  align-items: flex-end;
`;

const LabLogo = styled.a`
  position: absolute;
  right: 0;
  top: 40px;
`;

const HeroContainer = styled(Container)`
  padding: 40px 0 50px 0;
  position: relative;
`;  

const Column = styled.div`
  p {
    font-family: "Playfair Display";
    font-size: 1.2em;
    line-height: 1.4;
    color: white;
  }

  width: calc(50% - 20px);
`;

const WikipediaDesc = styled.div`
  font-family: "Playfair Display";
  font-size: 1.2em;
  line-height: 1.4;
  padding: 10px 0 0 0;
`; 

const Disclaimer = styled.div`
  font-family: "Brandon Text";
  font-size: 0.9em;
  font-style: italic;
  color: #888;
  line-height: 1.2;
`;

class About extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }
  componentDidMount(){
    this.loadData();
  }

  async loadData(){

    // const headers = {
    //   'Content-Type': 'application/json',
    //   'Accept': 'application/json',
    //   'X-Requested-With': 'XMLHttpRequest'
    // }

    // const response = await fetch(`/api/osm_pois/${this.props.match.params.id}.json`, { method: "get", headers });
    // if (response.ok) {
    //   let data = await response.json();
    //   this.setState({
    //     osm_poi: data.osm_poi,
    //     search_person: data.search_person
    //   })
    //   // this.props.dispatch(changeMatchedPeople(data.fullname_search_people, data.lastname_search_people));

    // } else {
    //   // let data = await response.json();
    //   console.log(response);
    // }
  }

  render() {
    let { windowWidth } = this.props;
    return (
      <Fragment>
        <Container>
          <Hero>
            <HeroContainer>
              <FlexSpaceBaseline>
                <Column>
                  <PageTitle>
                    About
                  </PageTitle>
                </Column>
                <Column>
                </Column>
              </FlexSpaceBaseline>
            </HeroContainer>
          </Hero>
          
          <FlexSpaceWrapper>


            <Column>
            </Column>


            <Column>
            </Column>
          
          

          </FlexSpaceWrapper>
          <Gutter h={50} />
        </Container> 
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth
  }
}

export default withRouter(connect(mapStateToProps)(About));