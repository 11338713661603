import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Container, FlexSpaceWrapper, Gutter, PageTitle, FormField, FormBorderWrapper, ButtonArea, BlackBtnInput, WhiteBtnA, TextFieldArea } from '../stylesheets/components';

import Routes from '../constants/routes';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { SearchPersonMapContainer } from '../components';
import { numberWithDelimiter, tablize } from '../utils';
import _ from 'lodash';
import { logIn, changeNoticeMessage } from '../actions';
import { Form, Field } from 'react-final-form';

const Fragment = React.Fragment;
const Hero = styled.div`
  background-color: white;
`;

const FlexSpaceBaseline = styled(FlexSpaceWrapper)`
  align-items: flex-end;
`;

const LabLogo = styled.a`
  position: absolute;
  right: 0;
  top: 40px;
`;

const HeroContainer = styled(Container)`
  padding: 40px 0 50px 0;
  position: relative;
`;  

const Column = styled.div`
  p {
    font-family: "Playfair Display";
    font-size: 1.2em;
    line-height: 1.4;
    color: white;
  }

  width: calc(50% - 20px);
`;

const WikipediaDesc = styled.div`
  font-family: "Playfair Display";
  font-size: 1.2em;
  line-height: 1.4;
  padding: 10px 0 0 0;
`; 

const Disclaimer = styled.div`
  font-family: "Brandon Text";
  font-size: 0.9em;
  font-style: italic;
  color: #888;
  line-height: 1.2;
`;


class NewPerson extends Component {
  constructor(props){
    super(props);
    this.state = {
      complete: false
    }
  }
  componentDidMount(){
    // this.loadData();
  }


  handleSubmit(e) {
    // debugger;
  }

  async handleSubmit(values) {
    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    // this.props.dispatch(changeProgress(true));
    const response = await fetch(Routes.api_search_people_path(), { method: "post", body: JSON.stringify(values), headers });
    if (response.ok) {
      let data = await response.json();
      this.props.history.push(Routes.search_person_path(data.search_person.id));
      
      return true;
    } else {
      let data = await response.json();
      // this.props.dispatch(changeProgress(false));
      return data;
    }
  }



  handleValidate(values) {
    const errors = {};

    if (values.search_person) {

      if (!values.search_person.first_name) {
        if (!errors.search_person) {
          errors.search_person = {};
        }
        errors.search_person.first_name = 'Required';
      }

      if (!values.search_person.last_name) {
        if (!errors.search_person) {
          errors.search_person = {};
        }
        errors.search_person.last_name = 'Required';
      }
    } else {
      if (!errors.search_person) {
        errors.search_person = {};
      }
      errors.search_person.first_name = 'Required';
      errors.search_person.last_name = 'Required';
    }

    return errors;
  }

  render() {
    let { windowWidth, form_authenticity_token, flash_alert  } = this.props;
    let { complete } = this.state;
    return (
      <Fragment>
        <Container>
          <Hero>
            <HeroContainer>
              <FlexSpaceBaseline>
                <Column>
                  <PageTitle>
                    Add a new person
                  </PageTitle>
                </Column>
                <Column>
                </Column>
              </FlexSpaceBaseline>
            </HeroContainer>
          </Hero>
          
          <FlexSpaceWrapper>


          {
              !complete ? 
              <Column>
                <Form
                  onSubmit={this.handleSubmit.bind(this)}
                  validate={this.handleValidate.bind(this)}
                  render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form method="post" onSubmit={handleSubmit}>
                      <input type="hidden" name="authenticity_token" value={form_authenticity_token} />
                      <FormBorderWrapper>
                      <FormField>
                          <label>First name</label>
                          <Field
                            name="search_person[first_name]"
                            >
                            {({ input, meta }) => (
                              <div className="right">
                                <input {...input} type="text" autoComplete="first_name" placeholder="e.g., Jefferson" />
                                {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                              </div>
                            )}
                          </Field>
                        </FormField> 

                        <FormField>
                          <label>Last name</label>
                          <Field
                            name="search_person[last_name]"
                            >
                            {({ input, meta }) => (
                              <div className="right">
                                <input {...input} type="text" autoComplete="last_name" placeholder="e.g., Davis" />
                                {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                              </div>
                            )}
                          </Field>
                        </FormField> 

                        <FormField>
                          <label>Wikipedia URL (optional)</label>
                          <Field
                            name="search_person[wikipedia_url]"
                            >
                            {({ input, meta }) => (
                              <div className="right">
                                <input {...input} type="text" autoComplete="wikipedia_url" placeholder="e.g., https://en.wikipedia.org/wiki/Jefferson_Davis" />
                                {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                              </div>
                            )}
                          </Field>
                        </FormField> 
                        <TextFieldArea>
                          <label>Description (optional)</label>
                          <Field
                            name="search_person[wikipedia_desc]"
                            autoFocus={true}
                            autoComplete="wikipedia_desc"
                          >
                            {({ input, meta }) => (
                              <div>
                                <textarea {...input} placeholder="e.g., Jefferson Davis is..." />
                                {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                              </div>
                            )}
                          </Field>
                        </TextFieldArea>
                      </FormBorderWrapper>
                      <Gutter h={20} />
                      
                      <ButtonArea>
                        <div>
                          <BlackBtnInput style={{ marginRight: 10}} type="submit" value="Submit" disabled={submitting || pristine} />
                        </div>
                      </ButtonArea>
                    </form>
                  )}
                />      
              </Column> : 
              <Column>
                Update is successfully complete. <br/><br/>
                  <Link to={`/search_people/${this.props.match.params.id}`}>
                    &lt; Back
                  </Link>
              </Column>
            }


            <Column>
            </Column>
          
          

          </FlexSpaceWrapper>
          <Gutter h={50} />
        </Container> 
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    form_authenticity_token: state.form_authenticity_token,
    flash_alert: state.flash_alert,
    flash_notice: state.flash_notice
  }
}

export default withRouter(connect(mapStateToProps)(NewPerson));