import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API_URL } from '../constants/defaults';
import { changeMapLoaded, changeMapZoom } from '../actions';
import _ from 'lodash';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import * as turf from '@turf/turf'

const Fragment = React.Fragment;

const MapDiv = styled.div`
  width: 100%;
  height: 500px;
  border:1px solid #eee;
`;


class OsmRoadMapContainer extends Component {
  constructor(props){
    super(props);

  }
  componentDidMount() {
    window.mapboxgl.accessToken = "pk.eyJ1IjoiZGF0YXBsdXNmZW1pbmlzbSIsImEiOiJjazl6aHc4cjQwNnRwM2xwZHJxZ2F4dm9vIn0.T9pZe5G7wOLEFLwdDlGBdg";//process.env.MAPBOX_TOKEN;

    this.map = new window.mapboxgl.Map({
      container: this.refsMapContainer,
      style: 'mapbox://styles/dataplusfeminism/ckd56g7q00bkm1imrngrva4uh',
      zoom: 3,
      minZoom: 3,
      center: [ -90, 40],
      // interactive: false
    });


    window.map = this.map;
    this.map.on('style.load', this.handleStyleLoad.bind(this));
  
  }

  async handleStyleLoad() {
    var bbox = turf.bbox(this.props.geojson);
    
    this.map.addSource('osm_road', {
      type: 'geojson',
      data: this.props.geojson
    });

    this.map.addLayer({
      'id': 'osm_road-layer',
      'type': 'line',
      'source': 'osm_road',
      'layout': {},
      'paint': {
        'line-color': '#000000',
        'line-opacity': 1,
        'line-width': 3
      }
    },  "admin-0-boundary-disputed");

    this.map.fitBounds([
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]]
    ], {
      padding: {top: 20, bottom:20, left: 20, right: 20},
      duration: 0
    })
  }

  

  render() {
    return (
      <MapDiv ref={c => { this.refsMapContainer = c; }}>
      </MapDiv>
    );
  }
}

let mapStateToProps = state => {

  return {
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight
  }
}

export default withRouter(connect(mapStateToProps)(OsmRoadMapContainer));