import React, { Component } from 'react'
import styled from 'styled-components';
import { StreetViewPanorama, withGoogleMap } from 'react-google-maps'; 
import { connect } from 'react-redux';

const OsmRoadStreetViewPanorama = withGoogleMap(props =>
  <StreetViewPanorama
    visible
    defaultPosition={props.coordinates}
    position={props.coordinates}
    pov={{ heading: props.heading, pitch: 15 }}
    zoom={0.5}
    options={{
      disableDefaultUI: true,
      enableCloseButton: false,
      panControl: true,
      fullscreenControl: true
    }}
  />
);


class StreetviewPano extends Component {
  constructor(props){
    super(props);
  }
  
  render() {
    let { streetviewCoordinates, streetviewHeading } = this.props;

    return (
      <OsmRoadStreetViewPanorama 
        coordinates={ streetviewCoordinates }
        heading={ streetviewHeading }
        containerElement={
          <div style={{ width: `100%`, height: 500 }} />
        }
        mapElement={<div style={{ height: "100%" }} />}
      />
    )
  }
}

let mapStateToProps = state => {
  return {
    streetviewHeading: state.streetviewHeading,
    streetviewCoordinates: state.streetviewCoordinates
  }
}

export default connect(mapStateToProps)(StreetviewPano);