export const windowResize = (dimension) => {
  return {
    type: 'WINDOW_RESIZE',
    payload: {
      windowWidth: dimension.width,
      windowHeight: dimension.height
    }
  }
}

export const changeMatchedPeople = (fullnameMatchedPeople, lastnameMatchedPeople) => {
  return {
    type: 'CHANGE_MATCHED_PEOPLE',
    payload: {
      fullnameMatchedPeople: fullnameMatchedPeople,
      lastnameMatchedPeople: lastnameMatchedPeople
    }
  }
}
