import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Container, FlexSpaceWrapper, Gutter, PageTitle, FormField, TextFieldArea, FormBorderWrapper, ButtonArea, BlackBtnInput, MenuTitle, SmallTitle, BlackBtnA } from '../stylesheets/components';
import Routes from '../constants/routes';
import { Link } from 'react-router-dom';
import { changeNoticeMessage, logIn } from '../actions';
import { withRouter } from 'react-router';
import { ImageDropzone } from '../components';
import { numberWithDelimiter, tablize } from '../utils';
import _ from 'lodash';
import { Form, Field } from 'react-final-form';
import AsyncSelect from 'react-select/async';
import RadioUnselected from '../assets/radio_unselected.svg';
import RadioSelected from '../assets/radio_selected.svg';

const Fragment = React.Fragment;

const Hero = styled.div`
  background-color: white;
`;

const FlexSpaceBaseline = styled(FlexSpaceWrapper)`
  align-items: flex-end;
`;

const LabLogo = styled.a`
  position: absolute;
  right: 0;
  top: 40px;
`;

const HeroContainer = styled(Container)`
  padding: 40px 0 50px 0;
  position: relative;
`;  

const Column = styled.div`
  p {
    font-family: "Playfair Display";
    font-size: 1.2em;
    line-height: 1.4;
    color: white;
  }

  width: calc(50% - 20px);
`;

const WikipediaDesc = styled.div`
  font-family: "Playfair Display";
  font-size: 1.2em;
  line-height: 1.4;
  padding: 10px 0 0 0;
`; 

const Disclaimer = styled.div`
  font-family: "Brandon Text";
  font-size: 0.9em;
  font-style: italic;
  color: #888;
  line-height: 1.2;
`;

const RadioArea = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  div.label {
    font-family: "Brandon Text";
    font-size:1.0em;
    margin-left: 10px;
    margin-top: 2px;
  }

   &:hover {
     opacity: 0.5;
   }
`;


class BuiltEnvironmentSearchPeople extends Component {
  constructor(props){
    super(props);
    this.state = {
      built_environment_search_people: [],
      built_environment_search_person: null,
      mode: 2,
      complete: false,
      searchMode: 1,
      searched_person: null,
      built_environment: null,
      built_environment_type: "",
      selectedBespId: null
    }
  }

  handleSearchMode(modeNumber) {
    this.setState({
      searchMode: modeNumber
    })
  }
  handleActivation(modeNumber) {
    
    this.setState({
      mode: modeNumber
    })
  
  }

  componentDidMount(){
    this.loadData();
  }

  async loadData(){

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    const response = await fetch(`/api/built_environment_search_people/${this.props.match.params.built_environment_type}/${this.props.match.params.built_environment_id}.json`, { method: "get", headers });
    
    if (response.ok) {
      let data = await response.json();
      this.setState({
        built_environment_search_people: data.built_environment_search_people,
        built_environment_search_person: _.first(data.built_environment_search_people),
        built_environment: data.built_environment,
        built_environment_type: data.built_environment_type,
        mode: data.built_environment_search_people.length === 0 ? 2 : 1
      });

      

      // this.props.dispatch(changeMatchedPeople(data.fullname_search_people, data.lastname_search_people));

    } else {
      // let data = await response.json();
      console.log(response);
    }
  }

  async handleUpdateSubmit(values) {
    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'X-Requested-With': 'XMLHttpRequest'
    }
    // this.props.dispatch(changeProgress(true));

    let formData = new FormData();

    formData.append("built_environment_search_person[notes]", values.built_environment_search_person.notes);
    formData.append("built_environment_search_person[user_id]", values.built_environment_search_person.user_id);
    _.each(values.built_environment_search_person.attachments, attachment => {
      formData.append("attachments[]", attachment);
    })

    const response = await fetch(Routes.api_update_built_environment_search_people_path(this.state.built_environment_search_person.properties.besp_id), { method: "put", body: formData, headers });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        complete: true
      });

    } else {
      let data = await response.json();

      // this.props.dispatch(changeProgress(false));
      return data;

    }


  }


  async handleCreateSubmit(values) {
    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'X-Requested-With': 'XMLHttpRequest'
    }
    // this.props.dispatch(changeProgress(true));

    let formData = new FormData();

    formData.append("built_environment_type", this.state.built_environment_type);
    formData.append("built_environment_id", this.state.built_environment.properties.id);
    formData.append("built_environment_search_person[notes]", values.built_environment_search_person.notes);
    formData.append("built_environment_search_person[user_id]", values.built_environment_search_person.user_id);
    _.each(values.built_environment_search_person.attachments, attachment => {
      formData.append("attachments[]", attachment);
    })

    formData.append("search_mode", this.state.searchMode);

    if (this.state.searchMode === 1) { // search from the database
      formData.append("built_environment_search_person[search_person_id]", this.state.searched_person.value);
    } else { // add new person
      formData.append("search_person[first_name]", values.search_person.first_name);
      formData.append("search_person[last_name]", values.search_person.last_name);
      formData.append("search_person[fullname]", `${values.search_person.first_name} ${values.search_person.last_name}`);
      formData.append("search_person[wikipedia_url]", values.search_person.wikipedia_url);
    } 

    const response = await fetch(Routes.api_create_built_environment_search_people_path(), { method: "post", body: formData, headers });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        complete: true
      });

    } else {
      let data = await response.json();

      // this.props.dispatch(changeProgress(false));
      return data;

    }


  }

  handleCreateValidate(values) {
    const errors = {};
    if (values.built_environment_search_person) {


      if (!values.built_environment_search_person.notes) {
        if (!errors.built_environment_search_person) {
          errors.built_environment_search_person = {};
        }
        errors.built_environment_search_person.notes = 'Required';
      }


    }

    if (this.state.searchMode === 1) {
      if (!this.state.searched_person) {
        if (!errors.built_environment_search_person) {
          errors.built_environment_search_person = {};
        }
        errors.built_environment_search_person.search_person_id = 'Required';
      }
    }

    if (this.state.searchMode === 2) {
      
      if (values.search_person) {

        if (!values.search_person.first_name) {
          if (!errors.search_person) {
            errors.search_person = {};
          }
          errors.search_person.first_name = 'Required';
        }

        if (!values.search_person.last_name) {
          if (!errors.search_person) {
            errors.search_person = {};
          }
          errors.search_person.last_name = 'Required';
        }
      } else {
        if (!errors.search_person) {
          errors.search_person = {};
        }
        errors.search_person.first_name = 'Required';
        errors.search_person.last_name = 'Required';
      }
      
    }

    return errors;
  }

  handleUpdateValidate(values) {
    const errors = {};
    if (values.built_environment_search_person) {


      if (!values.built_environment_search_person.notes) {
        if (!errors.built_environment_search_person) {
          errors.built_environment_search_person = {};
        }
        errors.built_environment_search_person.notes = 'Required';
      }


    }
    return errors;
  }

  determineType() {

    let { built_environment_search_person } = this.state;
    if (built_environment_search_person.properties.type === "OsmRoad") {
      return "Street";
    } else {
      return built_environment_search_person.properties.fclass;
    }

  }

  renderEditForm(){

    let { windowWidth, form_authenticity_token, current_user, user_signed_in } = this.props;
    let { built_environment_search_person, complete } = this.state;

    return (
      <Form
        onSubmit={this.handleUpdateSubmit.bind(this)}
        initialValues={{
          built_environment_search_person: {
            notes: built_environment_search_person.properties.notes,
            user_id: current_user.id
          }
        }}
        validate={this.handleUpdateValidate.bind(this)}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form method="post" onSubmit={handleSubmit}>
          <input type="hidden" name="authenticity_token" value={form_authenticity_token} />
          <FormBorderWrapper>
            <TextFieldArea>
              <label>Notes of the verification</label>
              <Field
                name="built_environment_search_person[notes]"
                autoFocus={true}
                autoComplete="notes"
                placeholder=""
              >
                {({ input, meta }) => (
                  <div>
                    <textarea {...input} placeholder="e.g., historical evidence found on the web" />
                    {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                  </div>
                )}
              </Field>
            </TextFieldArea>
            <FormField>
              <label>Attachments</label>
              <div className="right">

                {
                  _.map(built_environment_search_person.properties.attachments, attachment => {
                    return (
                      <div key={attachment.filename}>
                        <a href={attachment.url}>
                          {attachment.filename}
                        </a>
                      </div>
                    )
                  })
                } 
                {
                  built_environment_search_person.properties.attachments.length > 0 ? 
                  <Gutter h={10} /> : null
                }
                <Field name="built_environment_search_person[attachments]" component={ImageDropzone} />
              </div>
            </FormField>
            
            
          </FormBorderWrapper>

          <Gutter h={20} />
          <div>
            Verified by <b>{ current_user.fullname }</b>
          </div>
          <Gutter h={20} />
            <ButtonArea>
              <div>
                <BlackBtnInput style={{ marginRight: 10}} type="submit" value="Verify" disabled={submitting || pristine} />
              </div>
            </ButtonArea>
          </form>
        )}
      />
    )
  }

  handleInputChange(e){
    this.setState({
      searched_person: e
    })
  }

  async loadOptions(inputValue, callback){

      const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
  
      
      const response = await fetch(`/api/search_people/search.json?query=${inputValue}`, { method: "get", headers });
      if (response.ok) {
        let data = await response.json();
  
        callback(data.search_people);
  
      } else {
        let data = await response.json();
        console.log(response);
      }
    
    
  }

  renderCreateForm(){

    let { windowWidth, form_authenticity_token, current_user, user_signed_in } = this.props;
    let { built_environment_search_person, complete, searched_person, selectedBespId } = this.state;

    return (
      <Form
        onSubmit={this.handleCreateSubmit.bind(this)}
        validate={this.handleCreateValidate.bind(this)}
        initialValues={{
          built_environment_search_person: {
            search_person_id: searched_person ? searched_person.id : null,
            user_id: current_user.id
          }
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form method="post" onSubmit={handleSubmit}>

          <FormBorderWrapper>
            
            <FormField>
              <label>Type of Suggestion</label>
              <div className="right">
                <RadioArea onClick={this.handleSearchMode.bind(this, 1)}>
                  <img src={this.state.searchMode === 1 ? RadioSelected : RadioUnselected} />
                  <div className="label">
                    Search from the database
                  </div> 
                </RadioArea>
                <Gutter h={10} />
                <RadioArea onClick={this.handleSearchMode.bind(this, 2)}>
                  <img src={this.state.searchMode === 2 ? RadioSelected : RadioUnselected} />
                  <div className="label">
                    Add a new person to the database
                  </div> 
                </RadioArea>
                
              </div>
            </FormField>
            {
              this.state.searchMode === 1 ?
              <FormField>
                <label>Search from the database</label>
                <div className="right">
                  <AsyncSelect
                    cacheOptions
                    loadOptions={_.debounce(this.loadOptions, 1000) }
                    defaultOptions
                    value={searched_person}
                    onChange={this.handleInputChange.bind(this)}
                  />

                  <Field
                    name="built_environment_search_person[search_person_id]"
                    >
                    {({ input, meta }) => (
                      <Fragment>

                      {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                      </Fragment>
                    )}
                  </Field>

                </div>
              </FormField> : null
            }

            {
              this.state.searchMode === 2 ?
              <Fragment>

                <FormField>
                  <label>First name</label>
                  <Field
                    name="search_person[first_name]"
                    >
                    {({ input, meta }) => (
                      <div className="right">
                        <input {...input} type="text" autoComplete="first_name" placeholder="e.g., Jefferson" />
                        {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                      </div>
                    )}
                  </Field>
                </FormField> 

                <FormField>
                  <label>Last name</label>
                  <Field
                    name="search_person[last_name]"
                    >
                    {({ input, meta }) => (
                      <div className="right">
                        <input {...input} type="text" autoComplete="last_name" placeholder="e.g., Davis" />
                        {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                      </div>
                    )}
                  </Field>
                </FormField> 

                <FormField>
                  <label>Wikipedia URL (optional)</label>
                  <Field
                    name="search_person[wikipedia_url]"
                    >
                    {({ input, meta }) => (
                      <div className="right">
                        <input {...input} type="text" autoComplete="wikipedia_url" placeholder="e.g., https://en.wikipedia.org/wiki/Jefferson_Davis" />
                        {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                      </div>
                    )}
                  </Field>
                </FormField> 
              </Fragment>              
              : null
            }
            
            <TextFieldArea>
              <label>Notes of the verification</label>
              <Field
                name="built_environment_search_person[notes]"
                autoFocus={true}
                autoComplete="notes"
                placeholder=""
              >
                {({ input, meta }) => (
                  <div>
                    <textarea {...input} placeholder="e.g., historical evidence found on the web" />
                    {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                  </div>
                )}
              </Field>
            </TextFieldArea>
            <FormField>
              <label>Attachments</label>
              <div className="right">
                <Field name="built_environment_search_person[attachments]" component={ImageDropzone} />
              </div>
            </FormField>
            
            
          </FormBorderWrapper>
            <Gutter h={20} />
              <div>
                Verified by <b>{ current_user.fullname }</b>
              </div>
            <Gutter h={20} />
            <ButtonArea>
              <div>
                <BlackBtnInput style={{ marginRight: 10}} type="submit" value="Verify" disabled={submitting || pristine} />
              </div>
            </ButtonArea>
          </form>
        )}
      /> 
    );
  }

  handleBespClick(id){
    this.setState({
      selectedBespId: id
    });
  }

  render() {
    let { windowWidth, form_authenticity_token, current_user, user_signed_in } = this.props;
    let { selected_besp, built_environment_search_person, built_environment_search_people, complete, built_environment, selectedBespId } = this.state;

    let search_person = null;
    let county_subdivision = null; 
    let selectedBesp = null;

    if (built_environment_search_person && built_environment_search_person.properties) {
      county_subdivision = built_environment_search_person.properties.county_subdivision;
      search_person = built_environment_search_person.properties.search_person;
    }
    
    if (selectedBespId) {
      selectedBesp = _.find(built_environment_search_people, besp => besp.properties.besp_id === selectedBespId);
    }

    return (
      <Fragment>

        {
          user_signed_in && built_environment ? 
          <Container>
            <Hero>
              <HeroContainer>
                <FlexSpaceBaseline>
                  <Column>
                    <PageTitle>
                      Verify<br/>
                      { built_environment.properties.name }
                    </PageTitle>
                    <Gutter h={20} />
                    <FlexSpaceWrapper>
                      <Column>
                        {
                          search_person ?
                          <Fragment>
                            Named after <br/>
                            <SmallTitle>
                              <Link to={Routes.search_person_path(search_person.id)}>
                                { search_person.fullname }
                              </Link>
                            </SmallTitle>
                          </Fragment> : null
                        }
                        {
                          built_environment_search_person && built_environment_search_person.properties.user ?
                          <Fragment>
                            <Gutter h={20} />
                            Verified by <br/>
                            <SmallTitle>
                              { built_environment_search_person.properties.user.fullname }
                            </SmallTitle>
                          </Fragment> : null
                          }
                      </Column>
                      <Column>
                        {
                          built_environment_search_person ? 
                          <Fragment>
                            Last updated at 
                            <SmallTitle>
                              { built_environment_search_person.properties.updated_at }
                            </SmallTitle>
                          </Fragment> : null
                        }
                      </Column>
                    </FlexSpaceWrapper>
                   
                  </Column>
                  <Column>
                    
                  </Column>
                </FlexSpaceBaseline>
              </HeroContainer>
            </Hero>
            <Gutter h={20} />
            
            <FlexSpaceWrapper>

              {
                complete ? 
                <Column>
                  Update is successfully complete. <br/><br/>
                  <Link to={`/${tablize(built_environment.properties.type)}/${built_environment.properties.id}`}>
                    &lt; Back
                  </Link>
                </Column> :
                <Column>
                  {
                    search_person ?
                    <Fragment>
                      <RadioArea onClick={this.handleActivation.bind(this, 1)}>
                        <img src={this.state.mode === 1 ? RadioSelected : RadioUnselected} />
                        
                          <div className="label">
                            Update and verify that <b>{ built_environment.properties.name }</b> is named after <b>{search_person.fullname}</b>
                          </div>
                      </RadioArea>
                      <Gutter h={10} />
                    </Fragment> : null 
                  }
                  <RadioArea onClick={this.handleActivation.bind(this, 2)}>
                    <img src={this.state.mode === 2  ? RadioSelected : RadioUnselected} />
                    <div className="label">
                      Suggest a new person 
                    </div> 
                  </RadioArea>
                  <Gutter h={20} />
                  {
                    this.state.mode === 1 ?
                    this.renderEditForm() : 
                    this.renderCreateForm()
                  }     
                </Column>
              } 
             


              {
                !complete ? 
                <Column>
                  
                  <MenuTitle>
                    Verification History
                  </MenuTitle>
                  <Gutter h={25} />

                  <table>
                    <thead>
                      <tr>
                        <th className="left">
                          Named After
                        </th>
                        <th className="center">
                          Verified by
                        </th>
                        <th className="right">
                          Updated at
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        _.map(built_environment_search_people, (besp, i) => {
                          return (
                            <tr key={besp.properties.besp_id} onClick={this.handleBespClick.bind(this, besp.properties.besp_id)}>
                              <td className={besp.properties.besp_id === selectedBespId ? "bold" : ""}>
                                { besp.properties.search_person.fullname}
                              </td>
                              <td className={besp.properties.besp_id === selectedBespId ? "bold" : ""}>
                                { _.isNull(besp.properties.user) ? "" : besp.properties.user.fullname  }
                              </td>
                              <td className={besp.properties.besp_id === selectedBespId ? "right bold" : "right"}>
                                {besp.properties.updated_at}
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  {
                    selectedBesp ? 
                    <Fragment>
                      <Gutter h={40} />
                      <MenuTitle>
                        Show Verification
                      </MenuTitle>
                      <Gutter h={10} />
                      Named after <br/>
                      <SmallTitle>
                        <Link to={Routes.search_person_path(selectedBesp.properties.search_person.id)}>
                          { selectedBesp.properties.search_person.fullname }
                        </Link>
                      </SmallTitle>

                      <Gutter h={15} />
                      Verified by <br/>
                      <SmallTitle>
                        { _.isNull(selectedBesp.properties.user) ? "" : selectedBesp.properties.user.fullname  }
                      </SmallTitle>
                      <Gutter h={15} />
                      Notes of the Verification
                      <Gutter h={5} />
                      { selectedBesp.properties.notes }
                      <Gutter h={15} />
                      Attachments
                      <Gutter h={5} />
                      {
                        _.map(selectedBesp.properties.attachments, attachment => {
                          return (
                            <div key={attachment.url}>
                              <a href={attachment.url}>
                                { attachment.filename }
                              </a>
                            </div>
                          )
                        })
                      }
                      {
                        selectedBesp.properties.current ?
                        <Fragment>
                          <Gutter h={15} />
                          This verification is current verfication.
                        </Fragment> :
                        <Fragment>
                          <Gutter h={15} />
                          Revert to this verfication<br/>
                          <BlackBtnA href={`/api/built_environment_search_people/${selectedBesp.properties.besp_id}/revert`} data-method="post" data-confirm="Are you sure?">
                            Revert
                          </BlackBtnA>
                        </Fragment>
                      }
                      
                    
                    </Fragment> : null
                  }
                  

                </Column> : null 
              }
            
            

            </FlexSpaceWrapper>
            <Gutter h={50} />
          </Container> 
          : null 
        }
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    user_signed_in: state.user_signed_in,
    current_user: state.current_user,
    form_authenticity_token: state.form_authenticity_token
  }
}

export default withRouter(connect(mapStateToProps)(BuiltEnvironmentSearchPeople));