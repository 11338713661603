import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Container, FlexSpaceWrapper, Gutter, PageTitle, FormField, FormBorderWrapper, ButtonArea, BlackBtnInput, WhiteBtnA } from '../stylesheets/components';

import Routes from '../constants/routes';
import { Link } from 'react-router-dom';
import { changeNoticeMessage, logIn } from '../actions';
import { withRouter } from 'react-router';
import { SearchPersonMapContainer } from '../components';
import { numberWithDelimiter, tablize } from '../utils';
import _ from 'lodash';
import { Form, Field } from 'react-final-form';

const Fragment = React.Fragment;
const Hero = styled.div`
  background-color: white;
`;

const FlexSpaceBaseline = styled(FlexSpaceWrapper)`
  align-items: flex-end;
`;

const LabLogo = styled.a`
  position: absolute;
  right: 0;
  top: 40px;
`;

const HeroContainer = styled(Container)`
  padding: 40px 0 50px 0;
  position: relative;
`;  

const Column = styled.div`
  p {
    font-family: "Playfair Display";
    font-size: 1.2em;
    line-height: 1.4;
    color: white;
  }

  width: calc(50% - 20px);
`;

const WikipediaDesc = styled.div`
  font-family: "Playfair Display";
  font-size: 1.2em;
  line-height: 1.4;
  padding: 10px 0 0 0;
`; 

const Disclaimer = styled.div`
  font-family: "Brandon Text";
  font-size: 0.9em;
  font-style: italic;
  color: #888;
  line-height: 1.2;
`;


class Signin extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }
  componentDidMount(){
    // this.loadData();
  }


  async handleSubmit(values) {
    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    // this.props.dispatch(changeProgress(true));
    const response = await fetch(Routes.api_user_session_path(), { method: "post", body: JSON.stringify(values), headers });
    if (response.ok) {
      let data = await response.json();
      console.log(data);
      this.props.dispatch(logIn(data.user));
      this.props.dispatch(changeNoticeMessage("Successfully Signed In."))
      this.props.history.go(-1);
      // this.props.dispatch(changeProgress(false));

    } else {
      let data = await response.json();

      // this.props.dispatch(changeProgress(false));
      return data;

    }


  }

  handleValidate(values) {
    const errors = {};
    if (values.user) {


      if (!values.user.email) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.email = 'Required';
      }

      if (!values.user.password) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.password = 'Required';
      }



    }
    return errors;
  }


  render() {
    let { windowWidth, form_authenticity_token } = this.props;
    return (
      <Fragment>
        <Container>
          <Hero>
            <HeroContainer>
              <FlexSpaceBaseline>
                <Column>
                  <PageTitle>
                    Sign in
                  </PageTitle>
                </Column>
                <Column>
                </Column>
              </FlexSpaceBaseline>
            </HeroContainer>
          </Hero>
          
          <FlexSpaceWrapper>


            <Column>
              <Form
                 onSubmit={this.handleSubmit.bind(this)}
                validate={this.handleValidate.bind(this)}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                  <form method="post" onSubmit={handleSubmit}>
                  <input type="hidden" name="authenticity_token" value={form_authenticity_token} />
                    <FormBorderWrapper>
                      <FormField>
                        <label>Email</label>
                        <Field
                          name="user[email]"
                          autoFocus={true}
                          autoComplete="username"
                          placeholder="e.g., person@mit.edu"
                         >
                          {({ input, meta }) => (
                            <div className="right">
                              <input {...input} type="email" autoComplete="email" placeholder="e.g., person@mit.edu" />
                              {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                            </div>
                          )}
                        </Field>
                      </FormField>
                      <FormField>
                        <label>Password</label>
                        <Field
                          name="user[password]"
                        >
                          {({ input, meta }) => (
                            <div className="right">
                              <input {...input} type="password" autoComplete="password" placeholder="*******" />
                              {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                            </div>
                          )}
                        </Field>
                      </FormField>
                      
                    </FormBorderWrapper>
                    <Gutter h={20} />
                    
                    <ButtonArea>
                      <div>
                        <BlackBtnInput style={{ marginRight: 10}} type="submit" value="Login" disabled={submitting || pristine} />
                        <WhiteBtnA href={Routes.new_user_registration_path()}>
                          Create Account
                        </WhiteBtnA>
                      </div>
                      <div style={{textAlign: "right"}}>
                        <a href={Routes.new_user_password_path()}>Forgot password?</a>
                      </div>
                    </ButtonArea>
                  </form>
                )}
              />      
            </Column>


            <Column>
            </Column>
          
          

          </FlexSpaceWrapper>
          <Gutter h={50} />
        </Container> 
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    form_authenticity_token: state.form_authenticity_token
  }
}

export default withRouter(connect(mapStateToProps)(Signin));