import styled from 'styled-components';
import mixins from './mixins';
import { Link } from 'react-router-dom';
import media from "./media";

const FlexSpaceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexSpaceWrapperCenter = styled(FlexSpaceWrapper)`
  align-items: center;
`;

const FormBorderWrapper = styled.div`
  border: 1px solid black;
`;

const TextFieldArea = styled.div`

  label {
    color: black;
    display: block;
    padding: 13px;
    border-bottom: 1px solid black;
  }
  textarea {
    border: none;
    padding: 13px;
    font-family: "Brandon Text";
    font-size: 1.0em;
    width: calc(100% - 26px);
    height: 200px;
  }
  border-bottom: 1px solid black;

`;


const FormField = styled.div`
  border-bottom: 1px solid black;
  padding: 13px;
  display: flex;
  justify-content: space-between;

  label {
    color: black;
    width: calc(40% - 10px);
  }

  div.right {
    width: calc(60% - 10px);
    font-size: 1.0em;
    color: black;
    background:transparent;
  }
  

  span.error {
    color: #891e6b;
    font-size: 0.8em;

  }

  input {
    border: none;
    width: 330px;
    font-size: 1.0em;
    color: black;
    background:transparent;

    ${media.mobileLarge`
      width: calc(60% - 10px);
    `}
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 0.5; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      opacity: 0.5; /* Firefox */
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      opacity: 0.5; /* Firefox */
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;



const ButtonA = styled.a`
  display: inline-block;
  border: 1px solid black;
  color: black;
  border-radius: 50px;
  padding: 13px 20px 8px;
  font-size: 1.4em;

  ${media.mobileLarge`
    font-size:0.9em;
  `}
`;


const ButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin-right: 5px;
  }

  ${media.padLandscape`
    display: block;
  `}

  div {

  ${media.padLandscape`
    margin-bottom: 10px;
  `}
  }
`;

const SearchLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const MenuTitle = styled.h2`
  font-family: "Playfair Display";
  font-weight: 600;
  line-height: 1;
  font-size: 1.8571em;
`;



const BlackBtn = styled.button`
  background: #000000;
  border: 2px solid #000000;
  border-radius: 5px;
  padding: 10px;
  font-family: 'Brandon Text';
  font-size:1.2em;
  font-weight: 700;
  color: white;
  transform-origin: center;
  cursor:pointer;
  text-align: center;
  font-size:1.1em;

  &:hover {
    opacity: 1;
    background: #FFF;
    color: black;
    border: 2px solid #444;
  }

  &:disabled {
    background: #AAA;
    color: #BBB;
  }
`;


const SmallTitle = styled.div`
  font-family: "Playfair Display";
  font-size: 1.1em;
  font-weight: 600;
`;


const WhiteBtnA = styled.a`
  background: #FFF;
  border: 2px solid #000000;
  border-radius: 5px;
  padding: 10px;
  font-family: 'Brandon Text';
  font-size:1.2em;
  font-weight: 700;
  color: black;
  transform-origin: center;
  cursor:pointer;
  text-align: center;
  font-size:1.1em;

  &:hover {
    opacity: 1;
    background: #AAA;
    color: black;
    border: 2px solid #444;
  }
`;


const WhiteBtnLink = styled(Link)`
  background: #FFF;
  border: 2px solid #000000;
  border-radius: 5px;
  padding: 10px;
  font-family: 'Brandon Text';
  font-size:1.2em;
  font-weight: 700;
  color: black;
  transform-origin: center;
  cursor:pointer;
  text-align: center;
  font-size:1.1em;

  &:hover {
    opacity: 1;
    background: #AAA;
    color: black;
    border: 2px solid #444;
  }
`;



const BlackBtnInput = styled.input`
  background: #000000;
  border: 2px solid #000000;
  border-radius: 5px;
  padding: 10px;
  font-family: 'Brandon Text';
  font-size:1.2em;
  font-weight: 700;
  color: white;
  transform-origin: center;
  max-width: 200px;
  cursor:pointer;
  text-align: center;
  font-size:1.1em;

  &:hover {
    opacity: 1;
    background: #AAA;
    color: black;
    border: 2px solid #444;
  }

  &.search {
    width: calc(30% - 20px);
  }
  &:disabled {
    background: #AAA;
    color: #888;
    border: 2px solid #AAA;
  }
`;


const BlackBtnA = styled.a`
  background: #000000;
  border: 2px solid #000000;
  border-radius: 5px;
  padding: 10px;
  display: inline-block;
  font-family: 'Brandon Text';
  font-size:1.2em;
  font-weight: 700;
  color: white;
  transform-origin: center;
  max-width: 200px;
  cursor:pointer;
  text-align: center;
  font-size:1.1em;

  &:hover {
    opacity: 1;
    background: #AAA;
    color: black;
    border: 2px solid #444;
  }

  &.search {
    width: calc(30% - 20px);
  }
  &:disabled {
    background: #AAA;
    color: #888;
    border: 2px solid #AAA;
  }
`;


const BlackBtnLink = styled(Link)`
  background: #000000;
  border: 2px solid #000000;
  border-radius: 5px;
  padding: 10px;
  display: inline-block;
  font-family: 'Brandon Text';
  font-size:1.2em;
  font-weight: 700;
  color: white;
  transform-origin: center;
  max-width: 200px;
  cursor:pointer;
  text-align: center;
  font-size:1.1em;

  &:hover {
    opacity: 1;
    background: #AAA;
    color: black;
    border: 2px solid #444;
  }

  &.search {
    width: calc(30% - 20px);
  }
  &:disabled {
    background: #AAA;
    color: #888;
    border: 2px solid #AAA;
  }
`;




const Gutter = styled.div`
  height: ${props => props.h}px;
`;

const TextInput = styled.input`
  font-family: "Brandon Text";
  font-size: 1.286em;
  color: black;
  padding: 15px 10px;
  border: 2px solid black;
  border-radius: 5px;

  &.search {
    width: calc(70% - 20px);
  }
`;



const Container = styled.div`
  width: calc(1280px - 40px);
  padding: 0 20px;
  margin: 0 auto;
`;


const WhiteBtn = styled.button`
  background: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 5px;
  padding: 10px;
  font-family: 'Brandon Text';
  font-size:1.2em;
  font-weight: 700;
  transform-origin: center;
  margin-right: 10px;
  max-width: 200px;
  cursor:pointer;
  text-align: center;
  font-size:1.1em;

  &:hover {
    opacity: 1;
    background: black;
    color: white;
    border: 1px solid #444;
  }
`;

const PageTitle = styled.h2`

  font-family: "Playfair Display";
  font-size: 3.571em;
  line-height: 1.0;
  font-weight: 600;
  color: black;
`;

export {
  PageTitle,
  Gutter,
  Container,
  FlexSpaceWrapper,
  WhiteBtn,
  MenuTitle,
  SearchLayout,
  TextInput,
  BlackBtnInput,
  FlexSpaceWrapperCenter,
  BlackBtn,
  FormBorderWrapper,
  ButtonArea,
  FormField,
  WhiteBtnA,
  WhiteBtnLink,
  BlackBtnA,
  BlackBtnLink,
  SmallTitle,
  TextFieldArea
};