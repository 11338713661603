export const DEFAULT_WELCOME_STATE = {
  windowWidth: 1024,
  windowHeight: 768,
  mode: "fullname",
  current_user: null,
  user_signed_in: false,
  flash_notice: null,
  flash_alert: null,
  noticeMessage: null,
  form_authenticity_token: "",
  fullnameMatchedPeople: [],
  lastnameMatchedPeople: [],
  streetviewHeading: null,
  streetviewCoordinates: null
};

export const API_URL = process.env.NODE_ENV === "production" ? `${location.protocol}//${location.hostname}` : "http://localhost:3000"