import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Container, FlexSpaceWrapper, FlexSpaceWrapperCenter, Gutter, WhiteBtn, MenuTitle, SearchLayout, TextInput, BlackBtnInput, PageTitle } from '../stylesheets/components';
import Routes from '../constants/routes';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Pagination } from '../components';
import { numberWithDelimiter, tablize } from '../utils';
import _ from 'lodash';
import qs from 'qs';

const Fragment = React.Fragment;
const Hero = styled.div`
  background-color: white;
`;

const FlexSpaceBaseline = styled(FlexSpaceWrapper)`
  align-items: flex-end;
`;

const LabLogo = styled.a`
  position: absolute;
  right: 0;
  top: 40px;
`;

const HeroContainer = styled(Container)`
  padding: 40px 0 50px 0;
  position: relative;
`;  

const Column = styled.div`
  p {
    font-family: "Playfair Display";
    font-size: 1.2em;
    line-height: 1.4;
    color: white;
  }

  width: calc(50% - 20px);
`;

const WikipediaDesc = styled.div`
  font-family: "Playfair Display";
  font-size: 1.2em;
  line-height: 1.4;
  padding: 10px 0 0 0;
`; 

const Disclaimer = styled.div`
  font-family: "Brandon Text";
  font-size: 0.9em;
  font-style: italic;
  color: #888;
  line-height: 1.2;
`;

class Search extends Component {
  constructor(props){
    super(props);
    this.state = {
      page: 1,
      sp_page: 1,
      query: "",
      results_count: 0,
      pg_search_documents: [],
      sp_results_count: 0,
      search_people: []
    }
  }
  componentDidMount(){
    this.loadData();
  }

  handleSearchValue(e){
    this.setState({
      query: e.value
    });
  }


  async loadData(){

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    let params = qs.parse(this.props.location.search.replace("?", ""));
    
    const response = await fetch(`/api/search${this.props.location.search}`, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        page: data.page,
        sp_page: data.sp_page,
        query: params.query,
        results_count: data.results_count,
        pg_search_documents: data.pg_search_documents,
        sp_results_count: data.sp_results_count,
        search_people: data.search_people
      });
      // this.props.dispatch(changeMatchedPeople(data.fullname_search_people, data.lastname_search_people));

    } else {
      // let data = await response.json();
      console.log(response);
    }
  }

  render() {
    let { windowWidth } = this.props;
    let { 
      page,
      sp_page,
      query,
      results_count,
      pg_search_documents,
      sp_results_count,
      search_people
    } = this.state;
    return (
      <Fragment>
        <Container>
          <Hero>
            <HeroContainer>
              <FlexSpaceBaseline>
                <Column>
                  <PageTitle>
                    Search Results
                  </PageTitle>
                </Column>
                <Column>
                </Column>
              </FlexSpaceBaseline>
            </HeroContainer>
          </Hero>

          <FlexSpaceWrapper>


            <Column>
              <MenuTitle>
                Search Result for { query }
              </MenuTitle>

             

              <Gutter h={25} />
              <form action={Routes.search_path()} acceptCharset="UTF-8" method="get">
                <SearchLayout>
                  <TextInput onChange={this.handleSearchValue.bind(this) } className="search" type="text" name="query" id="query" value={query} placeholder="e.g., Robert E. Lee, Columbus Ave..." />
                  <BlackBtnInput className="search" type="submit" name="commit" value="Search" data-disable-with="Search" />
                </SearchLayout>
              </form>
            </Column>
            
          </FlexSpaceWrapper>

          <Gutter h={40} />

          <FlexSpaceWrapper>


            <Column>

            <FlexSpaceWrapperCenter>
              <MenuTitle>
                { numberWithDelimiter(results_count) } Streets and Place Names
              </MenuTitle>


              <Pagination 
                  page={page} 
                  per_page={30} 
                  total_count={results_count} 
                  page_params="page" 
                  url={Routes.search_path} 
                  restParams={{
                    query: query,
                    sp_page: sp_page
                  }}
                  />
              </FlexSpaceWrapperCenter>

              <Gutter h={25} />

              <table>
                <thead>
                  <tr>
                    <th className="left">
                      Name
                    </th>
                    <th>
                      Type
                    </th>
                    <th>
                      Location
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    _.map(pg_search_documents, pg_search_document => {
                      return (
                        <tr key={pg_search_document.searchable_id}>  
                          <td>
                            <Link to={`/${tablize(pg_search_document["searchable_type"])}/${pg_search_document["searchable_id"]}`}>
                              { pg_search_document["content"] }
                            </Link>
                          </td>
                          <td>
                            { pg_search_document["searchable_type"] }
                          </td>

                          <td>
                            { pg_search_document["cs_name"] }, { pg_search_document["state_abbr"] }
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>

            </Column>

            <Column>

                
                <FlexSpaceWrapperCenter>
                  <MenuTitle>
                    { numberWithDelimiter(sp_results_count) } People
                  </MenuTitle>


                  <Pagination 
                      page={sp_page} 
                      per_page={30} 
                      total_count={sp_results_count} 
                      page_params="sp_page" 
                      url={Routes.search_path} 
                      restParams={{
                        query: query,
                        page: page
                      }}
                      />
                </FlexSpaceWrapperCenter>
                <Gutter h={25} />

                <table>
                  <thead>
                    <tr>
                      <th className="left">
                        Name
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      _.map(search_people, search_person => {
                        return (
                          <tr key={search_person.id}>  
                            <td>
                              <Link to={Routes.search_person_path(search_person["id"])}>
                                { search_person["fullname"] }
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>

              </Column>

          </FlexSpaceWrapper>

          <Gutter h={50} />
        </Container> 
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth
  }
}

export default withRouter(connect(mapStateToProps)(Search));