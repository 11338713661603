import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Container, FlexSpaceWrapper, SmallTitle, FlexSpaceWrapperCenter, Gutter, WhiteBtn, MenuTitle, SearchLayout, TextInput, BlackBtnLink, PageTitle } from '../stylesheets/components';
import Routes from '../constants/routes';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { changePano } from '../actions';
import * as turf from '@turf/turf';
import { OsmRoadMapContainer, StreetviewPano } from '../components';
import _ from 'lodash';

const Fragment = React.Fragment;
const Hero = styled.div`
  background-color: white;
`;

const FlexSpaceBaseline = styled(FlexSpaceWrapper)`
  align-items: flex-end;
`;

const LabLogo = styled.a`
  position: absolute;
  right: 0;
  top: 40px;
`;

const HeroContainer = styled(Container)`
  padding: 40px 0 50px 0;
  position: relative;
`;  

const Column = styled.div`
  p {
    font-family: "Playfair Display";
    font-size: 1.2em;
    line-height: 1.4;
    color: white;
  }

  width: calc(50% - 20px);
`;

const WikipediaDesc = styled.div`
  font-family: "Playfair Display";
  font-size: 1.2em;
  line-height: 1.4;
  padding: 10px 0 0 0;

  a {
    text-decoration: underline;
  }
`; 

const Disclaimer = styled.div`
  font-family: "Brandon Text";
  font-size: 0.9em;
  font-style: italic;
  color: #888;
  line-height: 1.2;
`;

class OsmRoad extends Component {
  constructor(props){
    super(props);
    this.state = {
      osm_road: null,
      county_subdivision: null,
      search_person: null,
      built_environment_search_person: null
    }
  }
  componentDidMount(){
    this.loadData();
  }

  async loadData(){

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    const response = await fetch(`/api/osm_roads/${this.props.match.params.id}.json`, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        osm_road: data.osm_road,
        county_subdivision: data.county_subdivision,
        search_person: data.search_person,
        built_environment_search_person: data.built_environment_search_person
      })

      this.getCoordinates(data.osm_road);

    } else {
      // let data = await response.json();
      console.log(response);
    }
  }

  handleTrClick(url){
    this.props.history.push(url);
  }


  getCoordinates(geojson) {
    let pointOnPolygon = turf.pointOnFeature(geojson);

    let coordinates = new window.google.maps.LatLng(pointOnPolygon.geometry.coordinates[1], pointOnPolygon.geometry.coordinates[0]);
    let streetViewService = new window.google.maps.StreetViewService();

    streetViewService.getPanoramaByLocation(coordinates, 50, (panoData) => {
      if (panoData && panoData.location && panoData.location.latLng) {

        let result = {};
        // this computes the street view heading
        // srsly tho google, why not point to the latlng automatically?
        let panoCoordinates = panoData.location.latLng;
        result.heading = window.google.maps.geometry.spherical.computeHeading(
          panoCoordinates,
          coordinates
        );
        result.coordinates = coordinates;
        
        this.props.dispatch(changePano(result.heading, result.coordinates));
      } 
    });

  }

  render() {
    let { windowWidth, streetviewHeading, streetviewCoordinates, user_signed_in } = this.props;
    let { osm_road, county_subdivision, search_person } = this.state;

    console.log(user_signed_in);

    return (
      <Fragment>
        {
          osm_road ? 
          <Container>
            <Gutter h={50} />
            <FlexSpaceWrapper>
              <Column>
                <PageTitle>
                  { osm_road.properties.name }
                </PageTitle>
                <WikipediaDesc>
                  A Street&nbsp;
                  {
                    county_subdivision ? 
                    <Fragment>
                      in <Link to={Routes.county_subdivision_path(county_subdivision.gid)}>{ county_subdivision.name }, { county_subdivision.state_abbr }</Link>
                    </Fragment> : null 
                  }
                </WikipediaDesc>
                <Gutter h={20} />
                <FlexSpaceWrapper>
                  <Column>
                    Extracted Name <br/>
                    <SmallTitle>{ _.map(osm_road.properties.last_name.split(" "), n => _.capitalize(n)).join(" ") }</SmallTitle>

                    {/* <Gutter h={30} />
                    Inferred Race from the Census Name DB<br/>
                    <SmallTitle>{ _.capitalize(osm_road.properties.dominant_race) } ({ osm_road.properties.dominant_pct }%)</SmallTitle> */}
                  </Column>
                  <Column>
                    {
                      search_person ?
                      <Fragment>
                        Named after <br/>
                        <SmallTitle>
                          <Link to={Routes.search_person_path(search_person.id)}>
                            { search_person.fullname }
                          </Link>
                        </SmallTitle>
                        <Gutter h={30} />
                        
                        {
                          user_signed_in ? 
                          <Fragment>
                            Please verify { osm_road.properties.name } is named after { search_person.fullname }.
                            <Gutter h={5} />
                            <BlackBtnLink to={`/built_environment_search_people/OsmRoad/${osm_road.properties.id}`}>
                              Verify
                            </BlackBtnLink>
                          </Fragment> : null
                        }
                      </Fragment> :
                      <Fragment>
                        {
                          user_signed_in ? 
                          <Fragment>
                            <BlackBtnLink to={`/built_environment_search_people/OsmRoad/${osm_road.properties.id}`}>
                              Verify
                            </BlackBtnLink>
                          </Fragment> : null
                        }
                      </Fragment> 
                    }
                   
                  </Column>
                </FlexSpaceWrapper>

                <Gutter h={50} />

                <MenuTitle>
                  Articles about Name Controversies
                </MenuTitle>
                <Gutter h={25} />

                <table>
                  <thead>
                    <tr>
                      <th className="left">
                        Title
                      </th>
                      <th className="left">
                        Media Source
                      </th>

                      <th className="left">
                        Date
                      </th>
                    </tr>
                  </thead>
                </table>
{/* 
                <Gutter h={50} />

                <MenuTitle>
                  Petitions
                </MenuTitle>
                <Gutter h={25} />

                <table>
                  <thead>
                    <tr>
                      <th className="left">
                        Title
                      </th>

                      <th className="left">
                        Date
                      </th>
                    </tr>
                  </thead>
                </table> */}
              </Column>


              <Column>
                <OsmRoadMapContainer geojson={osm_road} /> 
                <Gutter h={20} />
                {
                  streetviewHeading && streetviewCoordinates ?
                  <StreetviewPano /> : null
                }
              </Column>
        
            </FlexSpaceWrapper>
            <Gutter h={50} />
          </Container> : null
        }
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    streetviewHeading: state.streetviewHeading,
    streetviewCoordinates: state.streetviewCoordinates,
    user_signed_in: state.user_signed_in
  }
}

export default withRouter(connect(mapStateToProps)(OsmRoad));