import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Container, FlexSpaceWrapper, FlexSpaceWrapperCenter, Gutter, WhiteBtn, MenuTitle, SearchLayout, BlackBtnInput, TextInput, WhiteBtnA } from '../stylesheets/components';
import Logo from '../assets/logo.svg';
import DFLogo from '../assets/df_logo.svg';
import ExternalIcon from '../assets/external.svg';
import Routes from '../constants/routes';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { numberWithDelimiter, tablize } from '../utils';
import _ from 'lodash';

const Fragment = React.Fragment;
const Hero = styled.div`
  background-color: black;
`;

const FlexSpaceBaseline = styled(FlexSpaceWrapper)`
  align-items: flex-end;
`;

const LabLogo = styled.a`
  position: absolute;
  right: 0;
  top: 40px;
`;

const HeroContainer = styled(Container)`
  padding: 40px 0 50px 0;
  position: relative;
`;  

const Column = styled.div`
  p {
    font-family: "Playfair Display";
    font-size: 1.2em;
    line-height: 1.4;
    color: white;
  }

  width: calc(50% - 20px);
`;

const Subtitle = styled.div`
  font-family: "Playfair Display";
  font-size: 1.6428em;
  line-height: 1.2;
  font-style: italic;
  color: white;
`;

const MoreBtn = styled(Link)`
  display: flex;
  span {
    font-family: "Brandon Text";
    font-size: 1.2em;
    font-weight: 700;
    margin-right: 5px;
    margin-top: 1px;
  }

`

class FirstPage extends Component {
  constructor(props){
    super(props);
    this.state = {
      search_people: [], 
      besp_osm_roads: [], 
      besp_places: [],  
      county_subdivisions: [],
      leftSearchValue: ""
    }
  }
  componentDidMount(){
    this.loadData();
  }

  async loadData(){

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    // this.props.dispatch(changeProgress(true));
    const response = await fetch("/api/first_page.json", { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        search_people: data.search_people, 
        besp_osm_roads: data.besp_osm_roads, 
        besp_places: data.besp_places,  
        county_subdivisions: data.county_subdivisions
      })
      // this.props.dispatch(changeMatchedPeople(data.fullname_search_people, data.lastname_search_people));

    } else {
      // let data = await response.json();
      console.log(response);
    }
  }

  handleLeftSearchValue(e){
    this.setState({
      leftSearchValue: e.value
    });
  }

  handleTrClick(url){
    this.props.history.push(url);
  }

  render() {
    let { user_signed_in } = this.props;
    return (
      <Fragment>
        <Hero>
          <HeroContainer>
            <FlexSpaceBaseline>
              <Column>
                <img src={Logo} alt="Audit the Streets: The U.S. Heritage Landscapes" />
                <Gutter h={20} />
                <Subtitle>
                  Exploring Quantitative Audits of <br/>
                  the U.S. Heritage Landscape
                </Subtitle>
                <Gutter h={10} />
                <p>
                  The death of George Floyd on May 25, 2020, in Minneapolis, USA, led to sustained protests, rallies and public outcry against systemic racism.
                </p>
              </Column>
              <Column>
                <p>
                  If you are interested in how and why we collected this data: 
                </p>
                <Gutter h={10} />
                <WhiteBtn>
                  Explore the Visual Essay
                </WhiteBtn>
              </Column>

              <LabLogo href="https://dataplusfeminism.mit.edu" target="_blank" rel="noopener noreferer">
                <img src={DFLogo} alt="D+F Lab Logo" />
              </LabLogo>
            </FlexSpaceBaseline>
          </HeroContainer>
        </Hero>
        <Gutter h={50} />
        <Container>
          <FlexSpaceWrapper>


            <Column>
              <MenuTitle>
                Search for a person or a street and a place 
              </MenuTitle>
              <Gutter h={25} />
              <form action={Routes.search_path()} acceptCharset="UTF-8" method="get">
                <SearchLayout>
                  <TextInput onChange={this.handleLeftSearchValue.bind(this) } className="search" type="text" name="query" id="query" value={this.state.leftSearchValue} placeholder="e.g., Robert E. Lee, Columbus Ave..." />
                  <BlackBtnInput className="search" type="submit" name="commit" value="Search" data-disable-with="Search" />
                </SearchLayout>
              </form>

              <Gutter h={50} />
              <FlexSpaceWrapperCenter>
                <MenuTitle>
                  People
                </MenuTitle>
                {
                  user_signed_in?
                  <WhiteBtnA href={Routes.new_search_person_path()}>
                    Add a new person
                  </WhiteBtnA> : null
                }
                {/* <MoreBtn to={Routes.search_people_path}>
                  <span>More</span> <img src={ExternalIcon} alt="external icon" />
                </MoreBtn> */}
              </FlexSpaceWrapperCenter>
              <Gutter h={20} />
              <table>
                <thead>
                  <tr>
                    <th className="left">
                      Name
                    </th>
                    <th className="right">
                      Matched
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    _.map(this.state.search_people, search_person => {
                      
                      return (
                        <tr onClick={this.handleTrClick.bind(this, Routes.search_person_path(search_person.id))}  key={search_person.id}>   
                          <td>
                            { search_person.fullname }
                          </td>
                          <td className="right">
                            { numberWithDelimiter(search_person.verified_count) }
                          </td>
                      </tr>
                      );
                    })
                  }
                </tbody>
              </table>
              
              <Gutter h={50} />

              <FlexSpaceWrapperCenter>
                <MenuTitle>
                  Street Names
                </MenuTitle>
                {/* <MoreBtn to={Routes.osm_roads_path}>
                  <span>More</span> <img src={ExternalIcon} alt="external icon" />
                </MoreBtn> */}
              </FlexSpaceWrapperCenter>
              <Gutter h={20} />
              <table>
                <thead>
                  <tr>
                    <th className="left">
                      Name
                    </th>                      
                    <th className="center">
                      Location
                    </th>
                    <th className="center">
                      Matched to
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    _.map(this.state.besp_osm_roads, besp => {
                      
                      return (
                        <tr key={besp.or_id}>  
                          <td>
                            <Link to={Routes.osm_road_path(besp.or_id)}>{ besp.or_name }</Link>
                          </td>                   
                          <td>
                            <Link to={Routes.county_subdivision_path(besp.cid)}>{ besp.county_name }, { besp.state_abbr }</Link>
                          </td>
                          <td>
                          <Link to={Routes.search_person_path(besp.sp_id)}>{ besp.sp_fullname }</Link>
                          </td>
                      </tr>
                      );
                    })
                  }
                </tbody>
              </table>

              <Gutter h={50} />

              <FlexSpaceWrapperCenter>
                <MenuTitle>
                  Place Names
                </MenuTitle>
                {/* <MoreBtn to={Routes.place_names_path}>
                  <span>More</span> <img src={ExternalIcon} alt="external icon" />
                </MoreBtn> */}
              </FlexSpaceWrapperCenter>
              <Gutter h={20} />
              <table>
                <thead>
                  <tr>
                    <th className="left">
                      Name
                    </th>                      
                    <th className="center">
                      Location
                    </th>
                    <th className="center">
                      Matched to
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    _.map(this.state.besp_places, besp => {
                      
                      return (
                        <tr key={besp.place_id}>  
                          <td>
                            <Link to={Routes[_.snakeCase(besp.place_type) + "_path"](besp.place_id)}>{ besp.place_name }</Link>
                          </td>                   
                          <td>
                          <Link to={Routes.county_subdivision_path(besp.cid)}>{ besp.county_name }, { besp.state_abbr }</Link>
                          </td>
                          <td>
                          <Link to={Routes.search_person_path(besp.sp_id)}>{ besp.sp_fullname }</Link>
                          </td>
                      </tr>
                      );
                    })
                  }
                </tbody>
              </table>

            </Column>


            <Column>
              <MenuTitle>
                Explore by Cities or Towns
              </MenuTitle>
              <Gutter h={25} />
              <form action={Routes.search_cities_path()} acceptCharset="UTF-8" method="get">
                <SearchLayout>
                  <TextInput onChange={this.handleLeftSearchValue.bind(this) } className="search" type="text" name="query" id="query" value={this.state.leftSearchValue} placeholder="e.g., Boston, Philadelphia..." />
                  <BlackBtnInput className="search" type="submit" name="commit" value="Search" data-disable-with="Search" />
                </SearchLayout>
              </form>
              <Gutter h={50} />
              
              <FlexSpaceWrapperCenter>
                <MenuTitle>
                  Cities and Towns
                </MenuTitle>
                <MoreBtn to={Routes.county_subdivisions_path()}>
                  <span>More</span> <img src={ExternalIcon} alt="external icon" />
                </MoreBtn>
              </FlexSpaceWrapperCenter>
              <Gutter h={20} />
              <table>
                <thead>
                  <tr>
                    <th className="left">
                      Name
                    </th>   
                    <th className="right">
                    Matched
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    _.map(this.state.county_subdivisions, cs => {
                      
                      return (
                        <tr key={cs.gid}>  
                          <td>
                            <Link to={Routes.county_subdivision_path(cs.gid)}>{ cs.name }, { cs.state_abbr }</Link>
                          </td>    
                          <td className="right">
                            { cs.verified_count }
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>


            </Column>
          

          </FlexSpaceWrapper>
          <Gutter h={50} />
        </Container>
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    user_signed_in: state.user_signed_in
  }
}

export default withRouter(connect(mapStateToProps)(FirstPage));