import React, { Component } from 'react';
import { FlexSpaceWrapper } from '../stylesheets/components';
import styled from 'styled-components';

const LinkArrow = styled.a`
  font-family: "Playfair Display";
  font-size: 2.0em;
  font-weight: 600;
`;

const FlexSpaceWrapperPagination = styled(FlexSpaceWrapper)`
  width: 50px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
`;

const Page = styled.div`
  margin: 2px 5px 0 5px;
`;

class Pagination extends Component {
  render() {
    let { 
      page, 
      per_page,
      total_count, 
      page_params,
      url,
      restParams,
      id
    } = this.props;
    
    let prevParams = {
      ...restParams
    };
    prevParams[page_params] = Number(page) - 1;
    
    let nextParams = {
      ...restParams
    };
    nextParams[page_params] = Number(page) + 1;

    let lastPage = Math.floor(Number(total_count) / Number(per_page)) + 1;

    let prevURL;
    let nextURL;

    if (id) {

      prevURL = url(id, prevParams);
      nextURL = url(id, nextParams);

    } else {

      prevURL = url(prevParams);
      nextURL = url(nextParams);

    }

    // console.log(page, per_page, nextParams[page_params], lastPage, prevParams[page_params]);


    return (
      <FlexSpaceWrapperPagination>
        { 
          nextParams[page_params] < lastPage ?
          <LinkArrow href={nextURL}>&gt;</LinkArrow> : null
        }
        <Page>
          { page }
        </Page>
        { 
          prevParams[page_params] > 0 ?
          <LinkArrow href={prevURL}>&lt;</LinkArrow> : null
        }
        
      </FlexSpaceWrapperPagination>
    )
  }
}

export default Pagination;