import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Container } from '../stylesheets/components'
import { Link } from 'react-router-dom';
import Routes from '../constants/routes';

const HeaderContainer = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  width: calc(100% - 20px);
  padding: 15px 10px;
  background-color: black;
  z-index: 9;
  border-bottom: 1px solid #333;
`;

const Title = styled(Link)`
  font-family: "Brandon Text";
  font-size: 1.0em;
  line-height: 1;
  font-weight: 700;
  color: white;
`;

const MenuList = styled.div`
  display: flex;
`;

const Menu = styled(Link)`
  margin-right: 20px;
  font-family: "Brandon Text";
  font-size: 1.0em;
  line-height: 1;
  font-weight: 700;
  color: white;

  &:last-child {
    margin-right: 0;
  }
`;

const HeaderFlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MenuA = styled.a`
  margin-right: 20px;
  font-family: "Brandon Text";
  font-size: 1.0em;
  line-height: 1;
  font-weight: 700;
  color: white;

  &:last-child {
    margin-right: 0;
  }
`;

const Fragment = React.Fragment;

class Header extends Component {
  render() {
    let { user_signed_in, current_user } = this.props;
    console.log("user signed in", user_signed_in);
    return (
      <HeaderContainer>
        <Container>
          <HeaderFlexWrapper>
            <Title to="/">
              Audit the Streets
            </Title>

            <MenuList>
              <Menu to="/about">
                About
              </Menu>

              <Menu to="/">
                Database
              </Menu>

              <Menu to="/visual-essay">
                Visual Essay
              </Menu>

              {
                user_signed_in ? 
                <Fragment>
                  <Menu to="/">
                    { current_user.fullname }
                  </Menu>
                  <MenuA href={Routes.destroy_user_session_path()} data-method="delete">
                    Sign out
                  </MenuA>
                </Fragment>
                 : 
                <Menu to={Routes.new_user_session_path()}>
                  Sign in
                </Menu>
              }
              
            </MenuList>
          </HeaderFlexWrapper>
        </Container>
      </HeaderContainer>
    )
  }
}

let mapStateToProps = state => {
  return {
    user_signed_in: state.user_signed_in,
    current_user: state.current_user
  }
}

export default connect(mapStateToProps)(Header);