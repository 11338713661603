import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { WhiteBtnA, Container, FlexSpaceWrapper, FlexSpaceWrapperCenter, Gutter, WhiteBtn, MenuTitle, SearchLayout, TextInput, BlackBtnInput } from '../stylesheets/components';
import Routes from '../constants/routes';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { SearchPersonMapContainer, Pagination } from '../components';
import { numberWithDelimiter, tablize } from '../utils';
import _ from 'lodash';

const Fragment = React.Fragment;
const Hero = styled.div`
  background-color: white;
`;

const FlexSpaceBaseline = styled(FlexSpaceWrapper)`
  align-items: flex-end;
`;

const LabLogo = styled.a`
  position: absolute;
  right: 0;
  top: 40px;
`;

const HeroContainer = styled(Container)`
  padding: 40px 0 50px 0;
  position: relative;
`;  

const Column = styled.div`
  p {
    font-family: "Playfair Display";
    font-size: 1.2em;
    line-height: 1.4;
    color: white;
  }

  width: calc(50% - 20px);
`;

const Title = styled.h2`

  font-family: "Playfair Display";
  font-size: 3.571em;
  line-height: 1.0;
  font-weight: 600;
  color: black;
`;

const WikipediaDesc = styled.div`
  font-family: "Playfair Display";
  font-size: 1.2em;
  line-height: 1.4;
  padding: 10px 0 0 0;
`; 

const Disclaimer = styled.div`
  font-family: "Brandon Text";
  font-size: 0.9em;
  font-style: italic;
  color: #888;
  line-height: 1.2;
`;

class Person extends Component {
  constructor(props){
    super(props);
    this.state = {
      search_person: null,
      verified_built_environment_search_people: [],
      unverified_built_environment_search_people: [],
      verified_count: 0,
      unverified_count: 0,
      page_verified: 1,
      page_not_verified: 1
    }
  }
  componentDidMount(){
    this.loadData();
  }

  async loadData(){

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    const response = await fetch(`/api/search_people/${this.props.match.params.id}.json${this.props.location.search}`, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        search_person: data.search_person,
        verified_built_environment_search_people: data.verified_built_environment_search_people,
        unverified_built_environment_search_people: data.unverified_built_environment_search_people,
        verified_count: data.verified_count,
        unverified_count: data.unverified_count,
        page_verified: data.page_verified,
        page_not_verified: data.page_not_verified
      })
      // this.props.dispatch(changeMatchedPeople(data.fullname_search_people, data.lastname_search_people));
      
    } else {
      // let data = await response.json();
      console.log(response);
    }
  }

  handleLeftSearchValue(e){
    this.setState({
      leftSearchValue: e.value
    });
  }

  handleTrClick(url){
    this.props.history.push(url);
  }

  render() {
    let { windowWidth, user_signed_in } = this.props;
    let { search_person, verified_count, unverified_count, page_verified, page_not_verified } = this.state;

    return (
      <Fragment>
        {
          _.isNull(search_person) ? null : 
          <Container>
            <Hero>
              <HeroContainer>
                <FlexSpaceBaseline>
                  <Column>
                    <Title>
                      <Fragment>
                        { search_person.first_name }<br/>
                        { search_person.last_name }
                      </Fragment>
                    
                    </Title>
                    {
                      _.isNull(search_person.wikipedia_desc) ? 
                      null : 
                      <WikipediaDesc>
                        { search_person.wikipedia_desc }
                      </WikipediaDesc>
                    }
                  </Column>
                  <Column>
                    {
                      user_signed_in?
                      <WhiteBtnA href={Routes.edit_search_person_path(search_person.id)}>
                        Edit
                      </WhiteBtnA> : null
                    }
                  </Column>
                </FlexSpaceBaseline>
              </HeroContainer>
            </Hero>
            
            <FlexSpaceWrapper>


              <Column>
                <FlexSpaceWrapperCenter>
                  <MenuTitle>
                    Verified Street &amp; Place Names
                  </MenuTitle>
                  <Pagination 
                    page={page_verified} 
                    per_page={15} 
                    total_count={verified_count} 
                    page_params="page_verified" 
                    url={Routes.search_person_path} 
                    id={search_person.id}
                    restParams={{
                      page_not_verified: page_not_verified
                    }}
                    />
                </FlexSpaceWrapperCenter>
                <Gutter h={20} />
                <table>
                  <thead>
                    <tr>
                      <th className="left">
                        Name
                      </th>                     
                      <th className="center">
                        Type
                      </th>                     
                      <th className="center">
                        Location
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      _.map(this.state.verified_built_environment_search_people, besp => {
                        
                        return (
                          <tr key={besp.place_id}>  
                            <td>
                              <Link to={Routes[_.snakeCase(besp.place_type) + "_path"](besp.place_id)}>{ besp.name }</Link>
                            </td>
                            <td className="center">
                              { besp.place_type }
                            </td>                   
                            <td>
                              {
                                besp.county_id ? 
                                <Link to ={Routes.county_subdivision_path(besp.county_id)}>
                                  { besp.county_name }, { besp.state_abbr }
                                </Link> : 
                                <Fragment>
                                { besp.county_name }, { besp.state_abbr }
                                </Fragment>
                              }
                             
                            </td>
                        </tr>
                        );
                      })
                    }
                  </tbody>
                </table>

                <Gutter h={50} />
                <FlexSpaceWrapperCenter>
                  <MenuTitle>
                    Unverified Street &amp; Place Names
                  </MenuTitle>
                  <Pagination 
                    page={page_not_verified} 
                    per_page={15} 
                    total_count={unverified_count} 
                    page_params="page_not_verified" 
                    url={Routes.search_person_path} 
                    id={search_person.id}
                    restParams={{
                      page_verified: page_verified
                    }}
                    />
                </FlexSpaceWrapperCenter>
                <Gutter h={10} />
                <Disclaimer>
                  Here are additional matching results usually using only last names. However, We don't know these names are really named after { search_person.fullname }, but there are some possibilities.
                </Disclaimer>
                <Gutter h={20} />
                <table>
                  <thead>
                    <tr>
                      <th className="left">
                        Name
                      </th>                     
                      <th className="center">
                        Type
                      </th>   
                                       
                      <th className="center">
                        Location
                      </th>     
                    </tr>
                  </thead>
                  <tbody>
                    {
                      _.map(this.state.unverified_built_environment_search_people, besp => {
                        
                        return (
                          <tr key={besp.searchable_id}>  
                            <td>
                              <Link to={Routes[_.snakeCase(besp.searchable_type) + "_path"](besp.searchable_id)}>{ besp.content }</Link>
                            </td>
                            <td className="center">
                              { besp.searchable_type }
                            </td>   
                            <td>
                            { besp["cs_name"] }, { besp["state_abbr"] }
                          </td> 
                        </tr>
                        );
                      })
                    }
                  </tbody>
                </table>

              </Column>


              <Column>
                <SearchPersonMapContainer {...this.state.search_person} />
              </Column>
            
            

            </FlexSpaceWrapper>
            <Gutter h={50} />
          </Container> 
        }
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    user_signed_in: state.user_signed_in
  }
}

export default withRouter(connect(mapStateToProps)(Person));