export const windowResize = (dimension) => {
  return {
    type: 'WINDOW_RESIZE',
    payload: {
      windowWidth: dimension.width,
      windowHeight: dimension.height
    }
  }
}

export const changeMatchedPeople = (fullnameMatchedPeople, lastnameMatchedPeople) => {
  return {
    type: 'CHANGE_MATCHED_PEOPLE',
    payload: {
      fullnameMatchedPeople: fullnameMatchedPeople,
      lastnameMatchedPeople: lastnameMatchedPeople
    }
  }
}

export const changePano = (heading, coordinates) => {
  return {
    type: 'CHANGE_PANO',
    payload: {
      streetviewHeading: heading,
      streetviewCoordinates: coordinates
    }
  }
}

export const changeNoticeMessage = (noticeMessage) => {
  return {
    type: "CHANGE_NOTICE_MESSAGE",
    payload: {
      noticeMessage: noticeMessage
    }
  }
};


export const updateSessionInfo = (user_signed_in, current_user, form_authenticity_token, flash_alert, flash_notice) => {
  return {
    type: 'UPDATE_SESSION_INFO',
    payload: {
      user_signed_in: user_signed_in,
      current_user: current_user,
      form_authenticity_token: form_authenticity_token,
      flash_alert: flash_alert,
      flash_notice: flash_notice
    }
  }
}

export const logIn = (current_user) => {
  return {
    type: "LOG_IN",
    payload: {
      current_user: current_user,
      user_signed_in: true
    }
  }
}
export const signOut = () => {
  return {
    type: "SIGN_OUT",
    payload: {
    }
  }
}
