import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { changeNoticeMessage } from '../actions';

const NoticeBox = styled.div`
  position: fixed;
  left: 50%;
  top: 80px;
  transform: translate(-50%, 0);
  width: 414px;
  background: white;
  padding: 15px;
  line-height: 1.3;
  font-weight: 600;
  box-shadow: 1px 1px 1px rgba(17, 17, 17, 0.15);
  z-index: 999999;
  color: #333;
  @media (max-width: 414px) {
    width: calc(100vw - 40px);
  }
`;
class Notice extends Component {
  
  componentDidMount() {
    _.delay(() => {
      this.props.dispatch(changeNoticeMessage(null));
    }, 5000);
  }

  componentWillUnmount(){

  }

  render() {
    let { noticeMessage } = this.props;
    return (
      <NoticeBox>
        { noticeMessage }
      </NoticeBox>
    )
  }
}

let mapStateToProps = state => {
  return {
    noticeMessage: state.noticeMessage
  }
}

export default connect(mapStateToProps)(Notice);