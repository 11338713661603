import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API_URL } from '../constants/defaults';
import { changeMapLoaded, changeMapZoom } from '../actions';
import _ from 'lodash';
import styled from 'styled-components';
import { withRouter } from 'react-router';

const Fragment = React.Fragment;

const MapDiv = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
`;


class MapContainer extends Component {
  constructor(props){
    super(props);

  }
  componentDidMount() {
    window.mapboxgl.accessToken = "pk.eyJ1IjoiZGF0YXBsdXNmZW1pbmlzbSIsImEiOiJjazl6aHc4cjQwNnRwM2xwZHJxZ2F4dm9vIn0.T9pZe5G7wOLEFLwdDlGBdg";//process.env.MAPBOX_TOKEN;

    this.map = new window.mapboxgl.Map({
      container: this.refsMapContainer,
      style: 'mapbox://styles/dataplusfeminism/ckd56g7q00bkm1imrngrva4uh',
      zoom: 4,
      minZoom: 4,
      maxZoom: 15,
      center: [ -90, 40],
      // interactive: false
    });


    window.map = this.map;
    this.map.on('style.load', this.handleStyleLoad.bind(this));
  
  }

  async handleStyleLoad(e) {
    // this.map.addSource('osm_roads', {
    //   "type": 'vector',
    //   "tiles": ["http:/tiles/osm_roads/{z}/{x}/{y}.pbf"],
    //   "minZoom": 3,
    //   "maxZoom": 22
    // });

    this.map.addSource('osm_roads', {
      "type": 'vector',
      "tiles": ["https://tile-server.dfdev.dataplusfeminism.mit.edu/data/audit-the-streets-0728-a/{z}/{x}/{y}.pbf"],
      "minZoom": 3,
      "maxZoom": 22
    });

    // this.map.addLayer({
    //   'id': 'osm_roads_layer',
    //   'source': 'osm_roads',
    //   'source-layer': 'audit_the_streets_osm_roads',
    //   'type': 'line',
    //   "minzoom": 3,
    //   'paint': {
    //     'line-opacity': 0.1,
    //     'line-color': "#000000"      
    //   }
    // }, "admin-0-boundary-disputed");


    this.map.addLayer({
      'id': 'osm_roads_layer',
      'source': 'osm_roads',
      'source-layer': 'audit_the_streets_osm_roads',
      'type': 'line',
      "minzoom": 3,
      'paint': {
        'line-opacity':[
          'match',
          ['get', 'dominant_race'],
          'black',
          0.5,
          'hispanic',
          0.5,
          'asian',
          0.5,
          'white',
          0.5,
          0.5
        ],
        'line-color': [
          'match',
          ['get', 'dominant_race'],
          'black',
          '#1161D9',
          'hispanic',
          '#D98911',
          'asian',
          '#D5D911',
          'white',
          '#08A751',
          /* other */ '#FF0000'
          ]        
      }
    }, "admin-0-boundary-disputed");

    this.map.on('click', 'osm_roads_layer', e => {
      if (e.features.length > 0) {
        console.log(e.features[0])
      }
    });

    
    // this.map.addSource('confederacy_monuments', {
    //   "type": 'vector',
    //   "tiles": [`${API_URL}/tiles/confederacy_monuments/{z}/{x}/{y}.pbf`],
    //   "minZoom": 3
    // });

    // this.map.addLayer({
    //   'id': 'confederacy_monuments_layer',
    //   'source': 'confederacy_monuments',
    //   'source-layer': 'public.confederacy_monuments',
    //   'type': 'circle',
    //   "minzoom": 3,
    //   'paint': {
    //     'circle-radius': 2,
    //     'circle-color': "#00FF00"        
    //   }
    // }, "admin-0-boundary-disputed");



    // this.map.addSource('confederacy_monuments', {
    //   "type": 'vector',
    //   "tiles": [`${API_URL}/tiles/confederacy_monuments/{z}/{x}/{y}.pbf`],
    //   "minZoom": 3
    // });

    // this.map.addLayer({
    //   'id': 'confederacy_monuments_layer',
    //   'source': 'confederacy_monuments',
    //   'source-layer': 'public.confederacy_monuments',
    //   'type': 'circle',
    //   "minzoom": 3,
    //   'paint': {
    //     'circle-radius': 2,
    //     'circle-color': "#00FF00"        
    //   }
    // }, "admin-0-boundary-disputed");


    // this.map.addSource('osm_lines_person', {
    //   "type": 'vector',
    //   "tiles": [`${API_URL}/tiles/osm_lines/person/{z}/{x}/{y}.pbf`],
    //   "minZoom": 3
    // });

    // this.map.addLayer({
    //   'id': 'osm_lines_person_layer',
    //   'source': 'osm_lines_person',
    //   'source-layer': 'public.planet_osm_line',
    //   'type': 'line',
    //   "minzoom": 3,
    //   'paint': {
    //     'line-color': "#FFFFFF"        
    //   }
    // }, "admin-0-boundary-disputed");



    // this.map.addSource('osm_points_person', {
    //   "type": 'vector',
    //   "tiles": [`${API_URL}/tiles/osm_points/person/{z}/{x}/{y}.pbf`],
    //   "minZoom": 3
    // });

    // this.map.addLayer({
    //   'id': 'osm_points_person_layer',
    //   'source': 'osm_points_person',
    //   'source-layer': 'public.planet_osm_point',
    //   'type': 'circle',
    //   "minzoom": 3,
    //   'paint': {
    //     'circle-radius': 2,
    //     'circle-color': "#0000FF"        
    //   }
    // }, "admin-0-boundary-disputed");


  }

  

  render() {
    return (
      <MapDiv ref={c => { this.refsMapContainer = c; }} className="map-container">
      </MapDiv>
    );
  }
}

let mapStateToProps = state => {

  return {
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight
  }
}

export default withRouter(connect(mapStateToProps)(MapContainer));