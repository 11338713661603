import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API_URL } from '../constants/defaults';
import { changeMapLoaded, changeMapZoom } from '../actions';
import _ from 'lodash';
import styled from 'styled-components';
import { withRouter } from 'react-router';

const Fragment = React.Fragment;

const MapDiv = styled.div`
  width: 100%;
  height: 500px;
  border:1px solid #eee;
`;


class SearchPersonMapContainer extends Component {
  constructor(props){
    super(props);

  }
  componentDidMount() {
    window.mapboxgl.accessToken = "pk.eyJ1IjoiZGF0YXBsdXNmZW1pbmlzbSIsImEiOiJjazl6aHc4cjQwNnRwM2xwZHJxZ2F4dm9vIn0.T9pZe5G7wOLEFLwdDlGBdg";//process.env.MAPBOX_TOKEN;

    this.map = new window.mapboxgl.Map({
      container: this.refsMapContainer,
      style: 'mapbox://styles/dataplusfeminism/ckd56g7q00bkm1imrngrva4uh',
      zoom: 2.8,
      minZoom: 2.8,
      maxZoom: 15,
      center: [ -95, 37],
      // interactive: false
    });


    window.map = this.map;
    this.map.on('style.load', this.handleStyleLoad.bind(this));
  
  }

  async handleStyleLoad() {

    this.map.addSource('search_people', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/search_people/${this.props.id}/{z}/{x}/{y}.pbf`],
      "minZoom": 2.8,
      "maxZoom": 22
    });

    this.map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'search_people',
        "source-layer": "public.search_people",
        filter: ['has', 'point_count'],
        maxZoom: 15,
        paint: {
          'circle-color': "#000000",
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      });
      
    this.map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'search_people',
      "source-layer": "public.search_people",
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      },
      paint: {
        'text-color': "white"
      }
    });


    this.map.addLayer({
      'id': 'verified_matches_layer_point',
      source: 'search_people',
      "source-layer": "public.search_people",
      'type': 'circle',
      'paint': {
        'circle-radius': {
          stops: [[2.8, 2], [11, 6], [16, 40]]
        },
        'circle-color': "rgba(0, 0, 0, 0.2)"
      }
    }, "admin-0-boundary-disputed");

    // const response = await fetch(`/api/search_people/${this.props.id}/verified_matches.json`, { method: "get" });

    // if (response.ok) {
    //   let responseText = await response.text();

    //   let geojson = JSON.parse(responseText);
      
    //   map.addSource('point_verified_matches', {
    //     type: 'geojson',
    //     data: geojson.all_geojson,
    //     cluster: true,
    //     clusterMaxZoom: 10,
    //     clusterRadius: 50 
    //   });

    //   map.addSource('osmroad_verified_matches', {
    //     type: 'geojson',
    //     data: geojson.line_geojson
    //   });

    //   map.addLayer({
    //     id: 'clusters',
    //     type: 'circle',
    //     source: 'point_verified_matches',
    //     filter: ['has', 'point_count'],
    //     maxZoom: 15,
    //     paint: {
    //       'circle-color': "#000000",
    //       'circle-radius': [
    //         'step',
    //         ['get', 'point_count'],
    //         20,
    //         100,
    //         30,
    //         750,
    //         40
    //       ]
    //     }
    //   });
      
    //   map.addLayer({
    //     id: 'cluster-count',
    //     type: 'symbol',
    //     source: 'point_verified_matches',
    //     filter: ['has', 'point_count'],
    //     layout: {
    //       'text-field': '{point_count_abbreviated}',
    //       'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    //       'text-size': 12
    //     },
    //     paint: {
    //       'text-color': "white"
    //     }
    //   });
    

    //   map.addLayer({
    //     'id': 'verified_matches_layer_point',
    //     'source': 'point_verified_matches',
    //     'type': 'circle',
    //     'filter': ['!=', ['get', 'type'], 'OsmRoad'],
    //     'paint': {
    //       'circle-radius': 5,
    //       'circle-color': "#000000"
    //     }
    //   }, "admin-0-boundary-disputed");

    //   map.addLayer({
    //     'id': 'verified_matches_layer_line',
    //     'source': 'osmroad_verified_matches',
    //     'type': 'line',
    //     'minZoom': 15,
    //     'paint': {
    //       'line-color': "#FF0000",
    //       'line-width': 5
    //     }
    //   }, "admin-0-boundary-disputed"); 

    // } 
  }

  

  render() {
    return (
      <MapDiv ref={c => { this.refsMapContainer = c; }}>
      </MapDiv>
    );
  }
}

let mapStateToProps = state => {

  return {
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight
  }
}

export default withRouter(connect(mapStateToProps)(SearchPersonMapContainer));