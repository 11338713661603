import React from 'react';
import { windowResize, updateSessionInfo } from '../actions';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Route, Switch, Link } from "react-router-dom";
import { Header, Notice } from '../components';
import { withRouter } from 'react-router';
import _ from 'lodash';
import SearchCities from './SearchCities';
import FirstPage from './FirstPage';
import Person from './Person';
import City from './City';
import Search from './Search';
import OsmPoi from './OsmPoi';
import OsmRoad from './OsmRoad';
import ConfederacyMonument from './ConfederacyMonument';
import PlaceName from './PlaceName';
import About from './About';
import Signin from './Signin';
import Signup from './Signup';
import BuiltEnvironmentSearchPeople from './BuiltEnvironmentSearchPeople';
import PasswordNew from './PasswordNew';
import PasswordEdit from './PasswordEdit';
import Routes from '../constants/routes';
import Publications from './Publications';
import NewPerson from './NewPerson';
import UnverifiedPlaces from './UnverifiedPlaces';
import EditPerson from './EditPerson';
import { Gutter } from '../stylesheets/components';
const Fragment = React.Fragment;
class Welcome extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount(){
    window.addEventListener('resize', this.resizeHandler.bind(this));
    this.resizeHandler();
    this.getSessionData();
  }

  async getSessionData(){

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    // this.props.dispatch(changeProgress(true));
    const response = await fetch("/api/current_sessions.json", { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      this.props.dispatch(updateSessionInfo(
        data.user_signed_in, 
        data.current_user, 
        data.form_authenticity_token,
        data.flash_alert,
        data.flash_notice
      ))
    } else {
      // let data = await response.json();
      console.log(response);
    }
  }


  resizeHandler(e){
    this.props.dispatch(windowResize({
      width: window.innerWidth,
      height: window.innerHeight
    }));
    
  }

  componentDidUpdate(){
  }

  render() {
    let { noticeMessage } = this.props;
    return (
      <Fragment>
        {
          noticeMessage ? 
          <Notice /> : null
        }
        <Header />
        <Gutter h={45} />
        <Switch>
          <Route exact path="/">
            <FirstPage />
          </Route>

          <Route exact path="/county_subdivisions/:id/unverified_places">
            <UnverifiedPlaces />
          </Route>

          <Route exact path="/built_environment_search_people/:built_environment_type/:built_environment_id">
            <BuiltEnvironmentSearchPeople />
          </Route>

          <Route exact path={Routes.new_user_session_path()}>
            <Signin />
          </Route>

          <Route exact path={Routes.new_user_registration_path()}>
            <Signup />
          </Route>

          <Route exact path={Routes.new_user_password_path()}>
            <PasswordNew />
          </Route>


          <Route exact path={Routes.edit_user_password_path()}>
            <PasswordEdit />
          </Route>

          <Route exact path="/search_people/new">
            <NewPerson />
          </Route>


          <Route exact path="/search_people/:id/edit">
            <EditPerson />
          </Route>

          <Route exact path="/search">
            <Search />
          </Route>
          <Route exact path="/search-cities">
            <SearchCities />
          </Route>

          <Route exact path="/search_people/:id">
            <Person />
          </Route>


          <Route exact path="/county_subdivisions/:id">
            <City />
          </Route>


          <Route exact path="/osm_pois/:id">
            <OsmPoi />
          </Route>


          <Route exact path="/place_names/:id">
            <PlaceName />
          </Route>

          <Route exact path="/confederacy_monuments/:id">
            <ConfederacyMonument />
          </Route>

          <Route exact path="/osm_roads/:id">
            <OsmRoad />
          </Route>

          <Route exact path="/about">
            <About />
          </Route>

          <Route exact path="/publications">
            <Publications />
          </Route>

        </Switch>

        
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight,
    noticeMessage: state.noticeMessage
  }
}

export default withRouter(connect(mapStateToProps)(Welcome));