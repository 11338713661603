import { DEFAULT_WELCOME_STATE } from '../constants/defaults';
import _ from 'lodash';

export default function welcomeReducer(state = DEFAULT_WELCOME_STATE, action){
  console.log(action.type, action.payload);
  switch(action.type) {
    case "WINDOW_RESIZE":
      return {
        ...state,
        windowWidth: action.payload.windowWidth,
        windowHeight: action.payload.windowHeight
      };
    case 'CHANGE_MATCHED_PEOPLE':
      return {
        ...state,
        fullnameMatchedPeople: action.payload.fullnameMatchedPeople,
        lastnameMatchedPeople: action.payload.lastnameMatchedPeople
      };
    case "CHANGE_NOTICE_MESSAGE":
      return {
        ...state,
        noticeMessage: action.payload.noticeMessage
      }
    case 'UPDATE_SESSION_INFO':
        return {
          ...state,
          user_signed_in: action.payload.user_signed_in,
          current_user: action.payload.current_user,
          form_authenticity_token: action.payload.form_authenticity_token,
          flash_notice: action.payload.flash_notice,
          flash_alert: action.payload.flash_alert
        }
    case 'CHANGE_PANO':
      return {
        ...state,
        streetviewHeading: action.payload.streetviewHeading,
        streetviewCoordinates: action.payload.streetviewCoordinates
      };
    case "LOG_IN": 
      return {
        ...state,
        current_user: action.payload.current_user,
        user_signed_in: true
      };
    case "SIGN_OUT":
      return {
        ...state,
        current_user: null,
        user_signed_in: false
      }
    default: 
      return {
        ...state
      };
  }

};