import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from 'styled-components';
import { WhiteBtnA } from '../stylesheets/components';

const CameraBox = styled.div`
  display: flex;
  align-items: center;
`;

const GutterH = styled.div`
  width: ${props => props.w}px;
`;

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 37
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


const ImageDropzone = ({ input }) => {

  const [files, setFiles] = useState([]);
  const onDrop = useCallback(acceptedFiles => {
    input.onChange(acceptedFiles);
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  // debugger;

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
        />
      </div>
    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  let props = {
    ...getInputProps(),
    multiple: true
  }
  
  return (
    <CameraBox {...getRootProps()}>
        <WhiteBtnA>Choose files...</WhiteBtnA>
        <GutterH w={5} />
        <div>
          { files.length } files
        </div>
        <input {...props} />
    </CameraBox>
  );
};

export default ImageDropzone;
